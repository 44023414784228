const baseUrl = '';
const responseHandle = (res) => {
    if (res.status == 200) {
        return res.json();
    } else if (res.status == 500 || res.status == 400) {
        res.json.then(console.err);
        return;
    } else if (res.status == 403) {
        sessionStorage.clear();
        window.location.reload(false);
    }
}


const LoginUser = (userId, pwd) => {
    return fetch(baseUrl + '/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, pwd }),
    }).then(responseHandle)
}

const GetDevices = () => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + '/api/devices', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then(responseHandle)
}

const GetDevice = (deviceId) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/device/${deviceId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then(responseHandle)
}

const GetDeviceData = (deviceId) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/deviceData/${deviceId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then(responseHandle)
}

const GetTags = (deviceId) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/tags/${deviceId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then(responseHandle)
}

const DeleteTag = (tagId) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/tag/${tagId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': token
        }
    }).then(responseHandle)
}

const UpsertTag = (body) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/tag`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(body)
    }).then(responseHandle)
}

const GetDeviceConfigs = (deviceId) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/configs/${deviceId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then(responseHandle)
}

const UpdateFTP = ({ deviceId, body }) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/ftp/${deviceId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(body)
    }).then(responseHandle)
}


const Updatemqtt = ({ deviceId, body }) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/mqtt/${deviceId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(body)
    }).then(responseHandle)
}

const UpdateModbus = ({ deviceId, body }) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/modbus/${deviceId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(body)
    }).then(responseHandle)
}

const UpdateEthernet = ({ deviceId, body }) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/ethernet/${deviceId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(body)
    }).then(responseHandle)
}

const SyncConfigs = ({ deviceId }) => {
    const token = sessionStorage.getItem('token');
    return fetch(baseUrl + `/api/syncConfig/${deviceId}`, {
        method: 'GET',
        headers: {
            'Authorization': token
        },
    }).then(responseHandle)
}

export {
    LoginUser,
    GetDevices,
    GetDevice,
    GetDeviceData,
    GetTags,
    DeleteTag,
    UpsertTag,
    GetDeviceConfigs,
    UpdateFTP,
    Updatemqtt,
    UpdateModbus,
    UpdateEthernet,
    SyncConfigs
}