import { useRef } from 'react';
import { LoginUser } from '../api'
import './login.css';

const Login = () => {
    const userIdRef = useRef(null);
    const pwdRef = useRef(null);

    const login = () => {
        LoginUser(userIdRef.current.value, pwdRef.current.value)
            .then(res => {
                if (res && res.token) {
                    console.log("Logging In");
                    sessionStorage.setItem("loggedIn", true);
                    sessionStorage.setItem("token", res.token);
                    window.location.reload(false);
                }
            })

    }
    return <div className='main_screen'>
        <img src="/images/background_image.jpg" alt="" />
        <div className="main-containerlogin">
            <div className='containerlogin'>
                <div className='form'>

                    <center>
                        <img src='/images/white-logo.png' alt='logo' />
<br />
<br />
                        <h6 className='main_title'>'Welcome to WIZEIoT - Livwize's IoT Dashboard'</h6>
                        
                        <br />
                    </center>

                    <div className='input'>
                        <input ref={userIdRef} type="text" id='username' placeholder='Enter Your Username' />

                    </div>
                    <div className='input'>
                        <input ref={pwdRef} type="password" id='password' placeholder='Enter Your Password' />

                    </div>

                    <button className='login-btn' onClick={login} type='button'>Login</button>
                    {/* <a href="#">Forgot Password?</a> */}
                </div>
            </div>
            {/* <div className="login-container">
            <div className="title"> Login</div>
            <input ref={userIdRef} type="text" placeholder="username"></input>
            <input ref={pwdRef} type="password" placeholder="password"></input>
            <button type="button" onClick={login}>Login</button>
        </div> */}
        </div>
    </div>
        ;
};

export default Login;
