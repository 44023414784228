import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { GetDevices } from '../api'
import { useState, useEffect } from 'react';

export default function App() {
  const [devices, setDevices] = useState([]);
  const navigate = useNavigate();
  const goToDeviceData = (d) => {
    navigate(`/deviceData?deviceId=${d.deviceId}`);
  }

  useEffect(() => {
    GetDevices().then(res => {
      if (res && res.data) {
        setDevices(res.data)
        console.log(res.data);
      }
    })
  }, [])

  return <div style={{ display: 'block', }} className="maindev">

    <div className='container_main'>
      <div className="row">
        <div className="col-md-12">
          <Table striped hover responsive="md">
            <thead>
              <tr>
                <th>Device</th>
                <th>Device Type</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {devices.map(d =>
                <tr>
                  <td>{d.name}</td>
                  <td>{d.deviceType}</td>
                  <td onClick={() => goToDeviceData(d)}> <span className='device_link'><i className="fa fa-external-link"></i> View</span> </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  </div>
};