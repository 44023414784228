import { useEffect } from "react";
import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import './tags.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
const AdminDashboard = () => {


    return <div style={{ display: 'block', }} className="maindev">

        <div className='container_main '>

            <div className="row">
                <div className="col-md-12">
                    <Table striped hover responsive="md">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Industry</th>
                                <th>Location</th>
                                <th>Address</th>
                                <th>No. Of Device</th>
                                <th>Last Active Device</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>123</td>
                                <td>Amit Kumar</td>
                                <td >Livwise</td>
                                <td>Indira Nagar</td>
                                <td>Lucknow</td>
                                <td>5</td>
                                <td>2038-01-19 03:14:07</td>

                            </tr>
                            <tr>
                                <td>123</td>
                                <td>Amit Kumar</td>
                                <td >Livwise</td>
                                <td>Indira Nagar</td>
                                <td>Lucknow</td>
                                <td>5</td>
                                <td>2038-01-19 03:14:07</td>
                            </tr>
                            <tr>
                                <td>123</td>
                                <td>Amit Kumar</td>
                                <td >Livwise</td>
                                <td>Indira Nagar</td>
                                <td>Lucknow</td>
                                <td>5</td>
                                <td>2038-01-19 03:14:07</td>
                            </tr>
                            <tr>
                                <td>123</td>
                                <td>Amit Kumar</td>
                                <td >Livwise</td>
                                <td>Indira Nagar</td>
                                <td>Lucknow</td>
                                <td>5</td>
                                <td>2038-01-19 03:14:07</td>
                            </tr>

                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    </div>



};

export default AdminDashboard;
