import { useEffect } from "react";
import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import './tags.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
const ClientManagement = () => {
    function AddClient(props) {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Organiation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-1 " controlId="formBasicEmail">
                            <Form.Label>ID</Form.Label>
                            <Form.Control type="text" placeholder="Enter ID" />
                        </Form.Group>
                        <Form.Group className="mb-1 " controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" />
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label>Industry Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Industry name" />
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" placeholder="Enter Location" />
                        </Form.Group>

                        <Form.Group className="mb-1" controlId="formBasicPassword">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" placeholder="Enter Address" />
                        </Form.Group>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    <Button onClick={props.onHide}>Close</Button>

                </Modal.Footer>
            </Modal>
        );
    }
    function EditClient(props) {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Organization
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-1 " controlId="formBasicEmail">
                            <Form.Label>ID</Form.Label>
                            <Form.Control type="text" placeholder="Enter ID" />
                        </Form.Group>
                        <Form.Group className="mb-1 " controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" />
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label>Industry Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Industry name" />
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" placeholder="Enter Location" />
                        </Form.Group>

                        <Form.Group className="mb-1" controlId="formBasicPassword">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" placeholder="Enter Address" />
                        </Form.Group>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Update
                    </Button>
                    <Button onClick={props.onHide}>Close</Button>

                </Modal.Footer>
            </Modal>
        );
    }
    function DeleteClient(props) {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delete Organization
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure? You want to delete.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Delete
                    </Button>
                    <Button onClick={props.onHide}>Close</Button>

                </Modal.Footer>
            </Modal>
        );
    }
    const [addModel, addModelShow] = React.useState(false);
    const [editModel, editModelShow] = React.useState(false);
    const [deleteModel, deleteModelShow] = React.useState(false);

    return <div style={{ display: 'block', }} className="maindev">

        <div className='container_main '>
            <div className="col-md-12 text-right ">
                <Button className='tagsbtn btn_cls' onClick={() => addModelShow(true)} >Add Client</Button>
                <AddClient
                    show={addModel}
                    onHide={() => addModelShow(false)}
                />
                <EditClient
                    show={editModel}
                    onHide={() => editModelShow(false)}
                />
                <DeleteClient
                    show={deleteModel}
                    onHide={() => deleteModelShow(false)}
                />
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Table striped hover responsive="md">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Industry</th>
                                <th>Location</th>
                                <th>Address</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>123</td>
                                <td>Amit Kumar</td>
                                <td >Livwise</td>
                                <td>Indira Nagar</td>
                                <td >Lucknow</td><td><button className="btn_user" onClick={() => editModelShow(true)}><i className="fa fa-edit" ></i></button> <button className="btn_user" onClick={() => deleteModelShow(true)}><i className="fa fa-trash"></i></button><BootstrapSwitchButton checked={true} onstyle="success" onlabel="OK" offlabel="Block" size="sm" /></td>
                            </tr>
                            <tr>
                                <td>123</td>
                                <td>Amit Kumar</td>
                                <td >Livwise</td>
                                <td>Indira Nagar</td>
                                <td >Lucknow</td><td><button className="btn_user" onClick={() => editModelShow(true)}><i className="fa fa-edit" ></i></button> <button className="btn_user" onClick={() => deleteModelShow(true)}><i className="fa fa-trash"></i></button> <BootstrapSwitchButton checked={true} onstyle="success" onlabel="OK" offlabel="Block" size="sm" /></td>
                            </tr>
                            <tr>
                                <td>123</td>
                                <td>Amit Kumar</td>
                                <td >Livwise</td>
                                <td>Indira Nagar</td>
                                <td >Lucknow</td><td><button className="btn_user" onClick={() => editModelShow(true)}><i className="fa fa-edit" ></i></button> <button className="btn_user" onClick={() => deleteModelShow(true)}><i className="fa fa-trash"></i></button> <BootstrapSwitchButton checked={true} onstyle="success" onlabel="OK" offlabel="Block" size="sm" /></td>
                            </tr>
                            <tr>
                                <td>123</td>
                                <td>Amit Kumar</td>
                                <td >Livwise</td>
                                <td>Indira Nagar</td>
                                <td >Lucknow</td><td><button className="btn_user" onClick={() => editModelShow(true)}><i className="fa fa-edit" ></i></button> <button className="btn_user" onClick={() => deleteModelShow(true)}><i className="fa fa-trash"></i></button> <BootstrapSwitchButton checked={true} onstyle="success" onlabel="OK" offlabel="Block" size="sm" /></td>
                            </tr>

                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    </div>



};

export default ClientManagement;
