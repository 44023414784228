
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './tags.css'
import Tagtable from './tagtable';
import Systemsettings from './Systemsettings.jsx';
import DeviceDataTable from './DeviceDataTable';
import { GetDevice } from '../api';
import moment from 'moment';

export default function DeviceConfig() {
  const [searchParams] = useSearchParams();
  const [device, setDevice] = useState({});
  useEffect(()=>{
    let deviceId = searchParams.get('deviceId')
    console.log("Device :: ",deviceId);
    GetDevice(deviceId).then(res => {
      if (res && res.data) {
        setDevice(res.data);
      }
    })
  },[]);
  return (
    <div style={{ display: 'block' }} className="maindash ">
      <div className="row my-3">
          <div className='col-md-3'><b>Device ID :</b> {device.deviceId}</div>
          <div className='col-md-3'><b>Device Name : </b>{device.name}</div>
          <div className='col-md-3'><b>Device Type : </b>{device.deviceType}</div>
          <div className='col-md-3'><b>Last Sync : </b>{moment(device.config_sync_time).utc().format("DD-MM-yy HH:mm:ss")}</div>
      </div>
      <Tabs defaultActiveKey="data">
        <Tab eventKey="data" title="Data">
          <DeviceDataTable device={device}/>
        </Tab>
        <Tab eventKey="tag" title="Tags">
          <Tagtable device={device}/>
        </Tab>
        <Tab eventKey="setting" title="Settings">
          <Systemsettings device={device}/>
        </Tab>
      </Tabs >
    </div >
  );
}
