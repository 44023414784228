import { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { GetDeviceConfigs, UpdateFTP, UpdateModbus, UpdateEthernet, SyncConfigs, Updatemqtt } from '../api';

const Systemsettings = ({ device }) => {
  const formRef = useRef();
  const mqttformRef = useRef();
  const modbusFormRef = useRef();
  const ethernetFormRef = useRef();
  const [deviceConfigs, setDeviceConfigs] = useState({});
  const [dhcpEnabled, setDhcpEnabled] = useState(false);

  useEffect(() => {
    if (deviceConfigs && deviceConfigs._id) {
      if (deviceConfigs.ftp) {
        formRef.current.host.value = deviceConfigs.ftp.host;
        formRef.current.port.value = deviceConfigs.ftp.port;
        formRef.current.username.value = deviceConfigs.ftp.username;
        formRef.current.password.value = deviceConfigs.ftp.password;
        formRef.current.protocol.value = deviceConfigs.ftp.protocol;
        formRef.current.timeout.value = deviceConfigs.ftp.timeout;
        formRef.current.path.value = deviceConfigs.ftp.path;
        formRef.current.ftpSyncHr.value = deviceConfigs.ftp.ftpSyncHr;
      }
      if (deviceConfigs.mqtt) {
        mqttformRef.current.host.value = deviceConfigs.mqtt.host;
        mqttformRef.current.port.value = deviceConfigs.mqtt.port;
        mqttformRef.current.username.value = deviceConfigs.mqtt.username;
        mqttformRef.current.password.value = deviceConfigs.mqtt.password;
        mqttformRef.current.pub_topic.value = deviceConfigs.mqtt.pub_topic;
        mqttformRef.current.sub_topic.value = deviceConfigs.mqtt.sub_topic;
        mqttformRef.current.did.value = deviceConfigs.mqtt.did;
      }
      if (deviceConfigs.modbus) {
        modbusFormRef.current.port.value = deviceConfigs.modbus.port;
        modbusFormRef.current.baudRate.value = deviceConfigs.modbus.baudRate;
        modbusFormRef.current.stopBit.value = deviceConfigs.modbus.stopBit;
        modbusFormRef.current.parity.value = deviceConfigs.modbus.parity;
        modbusFormRef.current.worlen.value = deviceConfigs.modbus.worlen;
      }
      if (deviceConfigs.ethernet) {
        setDhcpEnabled(deviceConfigs.ethernet.dhcp);
        ethernetFormRef.current.dhcp.checked = deviceConfigs.ethernet.dhcp;
        ethernetFormRef.current.ip.value = deviceConfigs.ethernet.ip || "";
        ethernetFormRef.current.subnet.value = deviceConfigs.ethernet.subnet || "";
        ethernetFormRef.current.gateway.value = deviceConfigs.ethernet.gateway || "";
        ethernetFormRef.current.dns.value = deviceConfigs.ethernet.dns || "";
        ethernetFormRef.current.alt_dns.value = deviceConfigs.ethernet.alt_dns || "";
      }
    }
  }, [deviceConfigs])

  useEffect(() => {
    if (device && device.deviceId) {
      FetchDeviceConfig();
    }
  }, [device])

  const FetchDeviceConfig = () => {
    GetDeviceConfigs(device.deviceId).then(res => {
      if (res && res.data) {
        setDeviceConfigs(res.data);
      }
    })
  }

  const onFtpSubmit = () => {
    let body = {
      host: formRef.current.host.value,
      port: formRef.current.port.value,
      username: formRef.current.username.value,
      password: formRef.current.password.value,
      protocol: formRef.current.protocol.value,
      timeout: formRef.current.timeout.value,
      path: formRef.current.path.value,
      ftpSyncHr: formRef.current.ftpSyncHr.value
    }
    UpdateFTP({ deviceId: device.deviceId, body }).then(res => {
      alert("FTP settings saved!");
      FetchDeviceConfig();
    })
  }

  const onmqttSubmit = () => {
    let body = {
      host: mqttformRef.current.host.value,
      port: mqttformRef.current.port.value,
      username: mqttformRef.current.username.value,
      password: mqttformRef.current.password.value,
      pub_topic: mqttformRef.current.pub_topic.value,
      sub_topic: mqttformRef.current.sub_topic.value,
      did: mqttformRef.current.did.value
    }
    Updatemqtt({ deviceId: device.deviceId, body }).then(res => {
      alert("MQTT settings saved!");
      FetchDeviceConfig();
    })
  }

  const onModbusSubmit = () => {
    let body = {
      port: modbusFormRef.current.port.value,
      baudRate: modbusFormRef.current.baudRate.value,
      stopBit: modbusFormRef.current.stopBit.value,
      parity: modbusFormRef.current.parity.value,
      worlen: modbusFormRef.current.worlen.value,
    }
    UpdateModbus({ deviceId: device.deviceId, body }).then(res => {
      alert("Modbus settings saved!");
      FetchDeviceConfig();
    })
  }

  const onEthernetSubmit = () => {
    let body = {
      dhcp: ethernetFormRef.current.dhcp.checked,
      ip: ethernetFormRef.current.ip.value,
      subnet: ethernetFormRef.current.subnet.value,
      gateway: ethernetFormRef.current.gateway.value,
      dns: ethernetFormRef.current.dns.value,
      alt_dns: ethernetFormRef.current.alt_dns.value,
    }
    UpdateEthernet({ deviceId: device.deviceId, body }).then(res => {
      alert("Ethernet settings saved!");
      FetchDeviceConfig();
    })
  }

  const SyncChanges = () => {

    SyncConfigs({ deviceId: device.deviceId }).then(res => {
      FetchDeviceConfig();
    })
  }

  const setDHCP = (e) => {
    setDhcpEnabled(e.target.checked);
  }

  return <div className="container_main cnt_div">
    {/* <div className="col-md-12 text-right ">
      <Button onClick={SyncChanges}><i className="fa fa-refresh"></i> Sync Now </Button>
    </div> */}
    <div className='row mainroz'>

      <div className='col-md-4 mldvl'>
        <div className="insetdx">
          <div className='row tophedfxdv'>
            <div className='topdvfx col-md-6'><strong>FTP Server</strong></div>
            <div className='topdvfx col-md-6 text-right'> <Button variant="md" onClick={onFtpSubmit} ><i className="fa fa-upload"></i> Update</Button></div>
          </div>
          <Form className='row dtvfx' ref={formRef}>


            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">

              <Form.Label>Host</Form.Label>
              <Form.Control name="host" type="text" placeholder="Hostname" />
            </Form.Group>
            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">

              <Form.Label>Port</Form.Label>
              <Form.Control name="port" type="number" placeholder="Port Number" />
            </Form.Group>


            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">
              <span className='todomx'></span>
              <Form.Label>Username</Form.Label>
              <Form.Control name="username" type="text" placeholder="Username" />
            </Form.Group>
            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">
              <span className='todomx'></span>
              <Form.Label>Password</Form.Label>
              <Form.Control name="password" type="text" placeholder="Password" />
            </Form.Group>


            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">
              <span className='todomx'></span>
              <Form.Label>Path</Form.Label>
              <Form.Control name="path" type="text" placeholder="Path Name" />
            </Form.Group>
            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">
              <span className='todomx'></span>
              <Form.Label>Timeout</Form.Label>
              <Form.Control name="timeout" type="number" placeholder="In Sec" />
            </Form.Group>


            <Form.Group className="col-md-6 mb-1" controlId="formBasicPassword">
              <Form.Label>Protocol</Form.Label>
              <Form.Select name="protocol">
                <option value="ftp">FTP</option>
                <option value="sftp">SFTP</option>
                <option value="ftps">FTPS</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">
              <span className='todomx'></span>
              <Form.Label>Sync Hour</Form.Label>
              <Form.Control name="ftpSyncHr" type="number" placeholder="Hour in 24hr clock" />
            </Form.Group>


          </Form>

        </div>

      </div>

      <div className='col-md-4 mldvl'>
        <div className="insetdx">
          <div className='row tophedfxdv'>
            <div className='topdvfx col-md-6'><strong>MQTT Server</strong></div>
            <div className='topdvfx col-md-6 text-right'> <Button variant="md" onClick={onmqttSubmit} ><i className="fa fa-upload"></i> Update</Button></div>
          </div>
          <Form className='row dtvfx' ref={mqttformRef}>


            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">

              <Form.Label>Host</Form.Label>
              <Form.Control name="host" type="text" placeholder="Hostname" />
            </Form.Group>
            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">

              <Form.Label>Port</Form.Label>
              <Form.Control name="port" type="number" placeholder="Port Number" />
            </Form.Group>
            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">
              <span className='todomx'></span>
              <Form.Label>Username</Form.Label>
              <Form.Control name="username" type="text" placeholder="Username" />
            </Form.Group>
            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">
              <span className='todomx'></span>
              <Form.Label>Password</Form.Label>
              <Form.Control name="password" type="text" placeholder="Password" />
            </Form.Group>
            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">
              <span className='todomx'></span>
              <Form.Label>PUB Topic</Form.Label>
              <Form.Control name="pub_topic" type="text" placeholder="Topic Name" />
            </Form.Group>
            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">
              <span className='todomx'></span>
              <Form.Label>SUB Topic</Form.Label>
              <Form.Control name="sub_topic" type="text" placeholder="Topic Name" />
            </Form.Group>
        
            <Form.Group className="mb-1 col-md-6" controlId="formBasicPassword">
              <span className='todomx'></span>
              <Form.Label>Device ID</Form.Label>
              <Form.Control name="did" type="text" placeholder="Device ID Name" />
            </Form.Group>
          </Form>

        </div>

      </div>

      <div className='col-md-4'>
        <div className="insetdx">
          <div className='row tophedfxdv'>
            <div className='topdvfx col-md-6'><strong>Ethernet</strong></div>
            <div className='topdvfx col-md-6 text-right'> <Button variant="md" onClick={onEthernetSubmit}><i className="fa fa-upload"></i> Update</Button></div>
          </div>
          <Form className='row dtvfx' ref={ethernetFormRef}>
            <span className='mb-4'></span>
            <Form.Group className="mb-3 col-md-6" controlId="formBasicCheckbox">
              <Form.Label>DHCP Enabled</Form.Label>
              <Form.Check type="checkbox" name="dhcp" onClick={setDHCP} />
            </Form.Group>
            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
              <Form.Label>IP Address</Form.Label>
              <Form.Control type="text" name="ip" placeholder="IP Address" disabled={dhcpEnabled} />
            </Form.Group>


            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
              <Form.Label>Subnet Mask</Form.Label>
              <Form.Control type="text" name="subnet" placeholder="Subnet Mask" disabled={dhcpEnabled} />
            </Form.Group>

            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
              <Form.Label>Default Gateway</Form.Label>
              <Form.Control type="text" name="gateway" placeholder="Default Gateway" disabled={dhcpEnabled} />
            </Form.Group>

            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
              <Form.Label>DNS</Form.Label>
              <Form.Control type="text" name="dns" placeholder="DNS" disabled={dhcpEnabled} />
            </Form.Group>

            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
              <Form.Label>Alternate DNS</Form.Label>
              <Form.Control type="text" name="alt_dns" placeholder="Alternate DNS" disabled={dhcpEnabled} />
            </Form.Group>
          </Form>

        </div>
      </div>
      <div className='col-md-4'><div className="insetdx">
        <div className='row tophedfxdv'>
          <div className='topdvfx col-md-6'><strong>ModBus</strong></div>
          <div className='topdvfx col-md-6 text-right'> <Button variant="md" onClick={onModbusSubmit}><i className="fa fa-upload"></i> Update</Button></div>
        </div>
        <Form className='row dtvfx' ref={modbusFormRef}>
          <span className='mb-4'></span>
          <Form.Group className="mb-3 col-md-8" controlId="formBasicPassword">
            <Form.Label>UART1</Form.Label>
            <Form.Select name="port" disabled>
              <option value="1">UART</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
            <Form.Label>Baud Rate</Form.Label>
            <Form.Select name="baudRate" >
              <option>9600</option>
              <option>19200</option>
              <option>57600</option>
              <option>115200</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
            <Form.Label>Stop Bits</Form.Label>
            <Form.Select name="stopBit">
              <option>1</option>
              <option>2</option>
            </Form.Select>
          </Form.Group>



          <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
            <Form.Label>Parity</Form.Label>
            <Form.Select name="parity" >
              <option value="0">None(0)</option>
              <option value="1">Odd(1)</option>
              <option value="2">Even(2)</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>Wordlength</Form.Label>
            <Form.Select name="worlen">
              <option>7</option>
              <option>8</option>
            </Form.Select>
          </Form.Group>



        </Form>

      </div></div>








    </div>






  </div>;
};

export default Systemsettings;