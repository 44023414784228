import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from "./components/Sidebar/SideBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Device from "./pages/Device";
import DeviceConfig from "./pages/DeviceConfig";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import UserManagement from "./pages/userManagement";
import ClientManagement from "./pages/clientManagement";
import AdminDashboard from "./pages/adminDashboard";

// import Menu from './components/Menu';

function App() {
  const isLogin = sessionStorage.getItem("loggedIn");
  return (

    <Router>
      {!isLogin ? <Routes><Route path="*" element={<Login />} /></Routes> :
        <SideBar>
          <Routes>
            <Route path="/" element={<Device />} />
            <Route path="/deviceData" element={<DeviceConfig />} />
            {/* <Route path="/camerastatus" element={<Camerastatus />} />
          <Route path="/livemap" element={<Livemap />} />
          <Route path="/alert" element={<Alert />} />
          <Route path="/ControlPanel" element={<ControlPanel />} />
          <Route path="/control-panel/device" element={<Device />} />
          <Route path="/Settings/FileExplorer" element={<FileExplorer />} />
          <Route path="/Settings/Generalsettings" element={<Generalsettings />} />
          <Route path="/Settings" element={<Settings />} />

          <Route path="/Settings/Systemsettings" element={<Systemsettings />} />
          <Route path="/Settings/Systemhealth" element={<Systemhealth />} />
          <Route path="/Settings/Tagsettings" element={<Tagsettings />} />
          <Route path="/Settings/Tagstream" element={<Tagstream />} />
          <Route path="/Account" element={<Account />} /> */}
            <Route path="/adminDashboard" element={<AdminDashboard />} />
            <Route path="/clientManagement" element={<ClientManagement />} />
            <Route path="/UserManagement" element={<UserManagement />} />
            <Route path="/LogOut" element={<Logout />} />
            <Route path="*" location="/" />
          </Routes>
        </SideBar>}
    </Router>



  );


}



export default App;
