import React, { useEffect, useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { GetTags, UpsertTag, DeleteTag } from '../api';

import './tags.css';

export default function Tagtable({ device }) {
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState({});
    const [modalShow, setModalShow] = React.useState(false);

    const showAddTagModal = () => {
        setSelectedTag({});
        setModalShow(true);
    };

    const showUpdateTagModal = (t) => {
        setSelectedTag(t);
        setModalShow(true);
    };

    const FetchTagData = () => {
        GetTags(device.deviceId).then(res => {
            if (res && res.data) {
                setTags(res.data);
            }
        })
    }
    
    const DeleteTagData = (tag) => {
        DeleteTag(tag._id).then(res => {
            if (res && res.message == "deleted") {
                FetchTagData();
            }
        })
    }

    const SubmitTagData = (body) => {

        UpsertTag({ ...selectedTag, ...body }).then(res => {
            if (res && res.data) {
                setModalShow(false);
                FetchTagData();
            }
        })
    }

    useEffect(() => {
        if (device && device.deviceId) {
            FetchTagData();
        }
    }, [device])

    return (<div className='maindev' style={{ display: 'block' }}>
        <div className='container_main cnt_div'>
            <div className="col-md-12 text-right ">
                <Button className='tagsbtn btn_cls' onClick={showAddTagModal} >Add Tag</Button>
            </div>

            <div >
                <div className="row">
                    <div className="col-md-12">
                        <Table striped hover responsive="md tbltg">
                            <thead>
                                <tr>
                                    <th>Tag Name</th>
                                    <th>Channel Type</th>
                                    <th>Port</th>
                                    <th>Unit</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tags.map(t =>
                                    <tr>
                                        <td>{t.name}</td>
                                        <td>{t.channelType}</td>
                                        <td>{t.port}</td>
                                        <td>{t.units}</td>
                                        <td>
                                            <i className="fa fa-external-link" onClick={() => showUpdateTagModal(t)}></i>&nbsp;&nbsp;&nbsp;
                                            <i className="fa fa-remove"  onClick={() => DeleteTagData(t)}></i>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>

            <MyVerticallyCenteredModal
                device={device}
                show={modalShow}
                tag={selectedTag}
                submitTagData={SubmitTagData}
                onHide={() => setModalShow(false)}
            />
        </div>
    </div>
    )
}

function MyVerticallyCenteredModal(props) {
    const { device, tag, onHide, submitTagData, show } = props;

    //refs
    const formRef = useRef();

    const [channelType, setChannelType] = useState('');
    const [scale, setScale] = useState([]);

    useEffect(() => {
        setScale([]);
        if (show && tag && device && tag.deviceId == device.deviceId) {
            formRef.current.name.value = tag.name;
            formRef.current.channelType.value = tag.channelType;
            formRef.current.port.value = tag.port;
            formRef.current.regName.value = tag.regName;
            formRef.current.slaveId.value = tag.slaveId;
            formRef.current.address.value = tag.address;
            formRef.current.units.value = tag.units;
            formRef.current.dataType.value = tag.dataType;
            setChannelType(tag.channelType);
            setScale(tag.scale);
        }
    }, [show, tag]);

    const submitForm = () => {
        let body = {
            deviceId: device.deviceId,
            name: formRef.current.name.value,
            channelType: formRef.current.channelType.value,
            port: formRef.current.port.value,
            regName: formRef.current.regName.value,
            slaveId: formRef.current.slaveId.value,
            address: formRef.current.address.value,
            units: formRef.current.units.value,
            dataType: formRef.current.dataType.value,
            scale
        };
        console.log("Body :", body);
        submitTagData(body);
    }

    const onScaleChange = (index, key, value) => {
        scale[index][key] = (+value);
        setScale([...scale]);
    }

    const addScale = () => {
        setScale([...scale, {}]);
    };

    const removeScale = (i) => {
        console.log(i);
        scale.splice(i, 1);
        console.log(scale);
        setScale([...scale]);
    };

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">
                    {tag && tag._id ? 'Update' : 'Add'} Tag
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='col-md-12 mldvl'>
                    <div >

                        <Form ref={formRef} className='row dtvfx'>

                            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
                                <span className='todomx'></span>
                                <Form.Label>Name</Form.Label>
                                <Form.Control name="name" type="text" placeholder="Tag Name" />
                            </Form.Group>
                            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
                                <Form.Label>Channel Type</Form.Label>
                                <Form.Select name="channelType" onChange={(e)=>{setChannelType(e.target.value);}}>
                                    <option value='adc'>4-20mA (ADC)</option>
                                    <option value="modbus">Modbus (UART 1)</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword" hidden={channelType != 'adc'}>
                                <Form.Label>Port (4-20mA)</Form.Label>
                                <Form.Select name="port">
                                    <option value="1">4-20mA Ch 1</option>
                                    <option value="2">4-20mA Ch 2</option>
                                    <option value="3">4-20mA Ch 3</option>
                                    <option value="4">4-20mA Ch 4</option>
                                    <option value="5">4-20mA Ch 5</option>
                                    <option value="6">4-20mA Ch 6</option>
                                    <option value="7">4-20mA Ch 7</option>
                                    <option value="8">4-20mA Ch 8</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword" hidden={channelType != 'modbus'}>
                                <Form.Label>Function (Modbus)</Form.Label>
                                <Form.Select name="regName">
                                    <option>Holding Registers</option>
                                    <option>Input Registers</option>
                                    <option>Coil Status</option>
                                    <option>Input Status</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword" hidden={channelType != 'modbus'}>
                                <Form.Label>Slave ID</Form.Label>
                                <Form.Control name="slaveId" type="number" placeholder="ID" />
                            </Form.Group>

                            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword" hidden={channelType != 'modbus'}>
                                <Form.Label>Address</Form.Label>
                                <Form.Control name="address" type="number" placeholder="Register Add" />
                            </Form.Group>

                            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
                                <Form.Label>Units</Form.Label>
                                <Form.Control name="units" type="text" placeholder="unit text" />
                            </Form.Group>
                            
                            <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
                                <Form.Label>Data Type</Form.Label>
                                <Form.Select name="dataType">
                                    <option value="int">Integer</option>
                                    <option value="float">Float</option>
                                    <option value="swapfloat">Swap Float</option>
                                </Form.Select>
                            </Form.Group>



                            <Modal.Title id="contained-modal-title-vcenter">
                                <br />
                                Add Scale Here
                            </Modal.Title>


                            <div className="col-md-12">
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <label>Point Value</label>
                                    </div>
                                    <div className='col-md-4'>
                                        <label>Scale Value</label>
                                    </div>
                                    <div className='col-md-1'>
                                        <label>Remove</label>
                                    </div>
                                </div>

                                {scale.map((s, i) => (
                                    <div key={i} className='row'>
                                        <div className='col-md-4'>
                                            <input type="number" name={"point"+i} 
                                                value={scale[i].pv} 
                                                onChange={(e) => onScaleChange(i, 'pv', e.target.value)} />
                                        </div>
                                        <div className='col-md-4'>
                                            <input type="number" name={"scale"+i} 
                                                value={scale[i].sv} 
                                                onChange={(e) => onScaleChange(i, 'sv', e.target.value)} />
                                        </div>
                                        <div className='col-md-1'>
                                            <i className="fa fa-remove" onClick={() => removeScale(i)}></i>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    onClick={addScale}
                                    className="add-btn"
                                >
                                    <span>Add Scale</span>
                                </button>
                            </div>
                        </Form>

                    </div>


                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={submitForm}>Submit</Button>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}