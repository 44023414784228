import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { GetDeviceData } from '../api';

import './tags.css';
export default function DeviceDataTable({ device }) {
    const [deviceData, setDeviceData] = useState([]);
    const fetchDeviceData = () => {
        if (device && device.deviceId) {
            GetDeviceData(device.deviceId)
                .then(res => {
                    if (res && res.data) {
                        setDeviceData(res.data);
                    }
                })
        }
    }
    useEffect(() => {
        fetchDeviceData();
        setInterval(fetchDeviceData, 3 * 60000);
    }, [device]);
    return (<div className='maindev' style={{ display: 'block', }}>
        <div className='container_main cnt_div'>
            <div className="row">
                <div className="col-md-12">
                    <Table striped hover responsive="md tbltg">
                        <thead>
                            <tr>
                                <th>Tag Name</th>
                                <th>Channel Type</th>
                                <th>Point Value</th>
                                <th>Scaled Value</th>
                                <th>Unit</th>
                                <th>TimeStamp</th>

                            </tr>
                        </thead>
                        <tbody>
                            {deviceData.map(dd => (
                                <tr>
                                    <td>{dd.name}</td>
                                    <td>{dd.channelType}</td>
                                    <td>{dd.pv}</td>
                                    <td>{dd.sv}</td>
                                    <td>{dd.units}</td>
                                    <td>{moment(dd.created_at).utcOffset("+05:30").format("YYYY-MM-DD HH:mm:ss")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>

    </div>
    )
}